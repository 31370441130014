<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>

    <td>
      {{ item.additional.lastName }}
    </td>
    <td>
      {{ item.additional.firstName }}
    </td>
    <td>
      {{ item.additional.middleName }}
    </td>

    <td>
      {{ item.additional.cardNo }}
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.contractId"
        :items="ctx.src.contracts.items"
        item-text="number"
        item-value="id"
        readonly
      ></v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.programId"
        :items="ctx.src.programs.get(item.additional.programId)"
        item-text="name"
        item-value="id"
        readonly
      ></v-autocomplete>
    </td>

    <td>
      <v-text-field
        v-model="item.additional.startDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.endDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>
      {{ item.additional.amount }}
    </td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  })
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
