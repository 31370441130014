<template>
  <div class="view-wrapper ">
    <div class="view fsh-3 view-wrapper-insured">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="searchText"
          hide-details
          append-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>

        <v-text-field
          v-model="iin"
          hide-details
          append-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          placeholder="ИИН/Номер паспорта застрахованного лица"
          @keydown.enter="searchByIIN"
          @click:append="searchByIIN"
        ></v-text-field>
        <v-autocomplete
          v-if="!item"
          v-model="underwriterId"
          :items="ctx.src.org.activeByType('1')"
          :label="'Страховая компания'"
          item-text="fullName"
          item-value="id"
          clearable
          dense
          filled
          rounded
          class="underwriter"
        ></v-autocomplete>
        <v-checkbox v-model="showAll" label="Показать всех" class="mt-5 ml-5 mr-5">
        </v-checkbox>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <InsuredHistory :item="item"></InsuredHistory>
        <v-dialog
          v-if="rightFilter('d')"
          v-model="dialogDelete"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="!item || !item.id" v-bind="attrs" v-on="on">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить застрахованного?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="rem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="!item"
          v-model="searchByServiceCheckNumberText"
          hide-details
          append-icon="search"
          label="Поиск по контрольному номеру услуги"
          single-line
          dense
          filled
          rounded
          clearable
          @keydown.enter="searchByServiceCheckNumber"
          @click:append="searchByServiceCheckNumber"
        ></v-text-field>
        <InsuredImport v-if="rightFilter('w')" :vm="this"></InsuredImport>
        <v-btn icon color="primary" :loading="loading" @click="exp">
          <v-icon>cloud_download</v-icon>
        </v-btn>
      </v-toolbar>
      <List :vm="this"></List>
    </div>
    <div v-if="item" class="view fg-3">
      <v-tabs v-model="active_tab" class="toolbar">
        <v-tab>Общие</v-tab>
        <v-tab v-if="item.id">Программа</v-tab>
        <v-tab v-if="item.id">Визиты</v-tab>
        <v-tab v-if="item.id">Услуги</v-tab>
        <v-tab v-if="item.id">Резерв</v-tab>
        <v-tab v-if="item.id">Баланс</v-tab>
        <v-tab v-if="item.id">Бланки</v-tab>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <v-textarea
            v-model="contractNote"
            label="Примечание"
            class="mt-9 contract-note"
            outlined
            no-resize
            readonly
            height="25"
          >
          </v-textarea>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>

        </v-toolbar>
        <v-tab-item>
          <div class="view" style="position: absolute; overflow: hidden">
            <v-toolbar class="my-bar">
              <SaveButton
              :additional-rules="!insuredDetail || !insuredDetail.valid"
              :handler="save"
              >

              </SaveButton>
              <v-spacer></v-spacer>
            </v-toolbar>
            <Detail v-bind:item="item" :vm="this"></Detail>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Limits :vm="this"></Limits>
        </v-tab-item>
        <v-tab-item>
          <Visits :vm="this"></Visits>
        </v-tab-item>
        <v-tab-item>
          <ServicesList :vm="this" :byInvoice="false"></ServicesList>
        </v-tab-item>
        <v-tab-item>
          <ReservesList :vm="this"></ReservesList>
        </v-tab-item>
        <v-tab-item>
          <Balance :vm="this" :insuredId="item.id"></Balance>
        </v-tab-item>
        <v-tab-item>
          <BlanksList :vm="this"></BlanksList>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import ctx from "../ctx";
import Detail from "../components/insured/Detail";
import List from "../components/insured/List";
import Limits from "../components/insured/Limits";
import ServicesList from "../components/insured/ServicesList";
import Visits from "../components/insured/Visits";
import ReservesList from "../components/insured/ReservesList";
import Balance from "../components/balance/Master";
import BlanksList from "../components/insured/BlanksList";
import InsuredImport from "../components/insured/InsuredImport";
import rightFilter from "../filters/right.filter";
import InsuredHistory from "../components/insured/InsuredHistory.vue";
import SaveButton from "@/components/ButtonFacade";
import btoa from "../utils/btoa";

export default {
  data: () => ({
    ctx,
    rightFilter,
    item: undefined,
    detailShown: false,
    searchText: undefined,
    underwriterId: undefined,
    searchByServiceCheckNumberText: undefined,
    dialogDelete: false,
    invoice: false,
    loading: false,
    insuredDetail: undefined,
    balance: undefined,
    reservesList: undefined,
    servicesList: undefined,
    blanksList: undefined,
    iin: undefined,
    active_tab: null,
    showAll: false
  }),
  async mounted(){
      if(this.$route.query.cardNo){
        this.searchText = this.$route.query.cardNo;
        this.search();
        this.active_tab = 5;
        const response = await fetch.get(`/api/src/insured/get-by-card-no?cardNo=${this.$route.query.cardNo}`);
        this.item = response;
      }

      const res = await fetch.get("/api/auth/get-list");
      console.log("/api/auth/get-list ------- ", res); 
      
  },
  computed: {
    contractNote: function () {
      if(!this.item.contractId) return '';
      return this.ctx.src.contracts.get(this.item.contractId).note || '';
    }
  },
  watch: {
    item: function(val) {
      this.detailShown = val ? true : false;
    }
  },
  methods: {
    refreshDetail() {
      if (this.balance) this.balance.fetchItems();
      if (this.reservesList) this.reservesList.fetchItems();
      if (this.servicesList) this.servicesList.fetchItems();
    },
    add() {
      this.insuredList.add();
    },
    async rem() {
      await this.insuredList.rem();
    },
    search() {
      this.searchByServiceCheckNumberText = undefined;
      if (!this.searchText) this.underwriterId = undefined;
      this.insuredList.search(this.searchText, this.underwriterId, false);
    },
    searchByServiceCheckNumber() {
      this.searchText = undefined;
      this.underwriterId = undefined;
      this.insuredList.searchByServiceCheckNumber(
        this.searchByServiceCheckNumberText
      );
    },

    searchByIIN() {
      this.searchByServiceCheckNumberText = undefined;
      if (!this.searchText) this.underwriterId = undefined;
      this.insuredList.search(this.iin, this.underwriterId, true);
    },
    save() {
      if (this.insuredDetail.valid) this.insuredList.save();
    },
    close() {
      this.insuredList.close();
    },
    initList() {
      this.insuredList.init();
    },
    async exp() {
      this.loading = true;
      await this.insuredList.export();
      this.loading = false;
    }
  },
  components: {
    SaveButton,
    Detail,
    List,
    Limits,
    ServicesList,
    Visits,
    ReservesList,
    Balance,
    BlanksList,
    InsuredImport,
    InsuredHistory
  },
};
</script>

